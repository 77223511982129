import { getCookieWithoutRedirect, setCookie } from "./cookie";

export function copyWithRef(url: string, ref?: string | undefined) {
  if (!navigator?.clipboard) return;

  if (ref) {
    navigator.clipboard.writeText(`${url}?referral=${ref}`);
    return;
  }

  navigator.clipboard.writeText(url);
}

export function getRefFromCookie(): string | undefined {
  const ref = getCookieWithoutRedirect("referral");

  if (ref) {
    return ref;
  }

  return undefined;
}

export function setRefCookie(ref: string): void {
  if (typeof document === "undefined") return;

  const existingRef = getCookieWithoutRedirect("referral");
  if (existingRef) return;

  setCookie("referral", ref, 30);
}

